import { useLazyQuery } from "@apollo/client";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useRouter, useSearchParams } from "next/navigation";
import { KeyboardEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useDebounce } from "usehooks-ts";

import { getArtworkAutocompleteResultsQuery } from "@/api/queries/search";
import ArtworkMediaTypeDropdown from "@/components/ArtworkMediaTypeDropdown";
import {
  GetArtworkAutocompleteResultsQuery,
  GetArtworkAutocompleteResultsQueryVariables,
} from "@/gql/graphql";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";
import autocompleteAtom from "@/recoil/autocomplete/atom";
import { routes } from "@/utils/routes";
import { zArtworkMediaTypeDropdown, zSearchBar, zSearchBarOptionItem } from "@/utils/zIndex";

interface Props {
  className?: string;
  currentState?: (isOpen: boolean) => void;
}

const ArtworkSearchAutocomplete = (props: Props) => {
  const router = useRouter();

  const searchParams = useSearchParams();
  const [autocomplete, setAutocomplete] = useRecoilState(autocompleteAtom);
  const [options, setOptions] = useState<
    GetArtworkAutocompleteResultsQuery["getArtworkAutocompleteResults"]
  >([]);
  const debouncedInputQuery = useDebounce<string>(autocomplete.searchQuery, 250);

  //
  const { t } = useCustomTranslation("home");
  const [isOpen, setIsOpen] = useState(false);

  const [fetchAutocompleteResult, { data: autocompleteResults }] = useLazyQuery<
    GetArtworkAutocompleteResultsQuery,
    GetArtworkAutocompleteResultsQueryVariables
  >(getArtworkAutocompleteResultsQuery);

  /**
   * Given we're updating everything through the atom, query the autocomplete in an useEffect
   */
  useEffect(() => {
    if (debouncedInputQuery) {
      fetchAutocompleteResult({ variables: { query: debouncedInputQuery } });
    }
  }, [debouncedInputQuery, fetchAutocompleteResult]);

  useEffect(() => {
    props.currentState?.(isOpen);
  }, [isOpen]);

  const handleOnSearchQueryChange = (newQuery: string) => {
    setAutocomplete({
      ...autocomplete,
      searchQuery: newQuery ?? "",
    });
  };

  const handleClickAutocomplete = (search: string) => () => {
    const params = new URLSearchParams();
    params.set("keyword", search);
    router.push(routes.search(/*page*/ 1, "image", params, /*withDefaultParams*/ true));
  };

  const handlePressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === "enter") {
      const params = new URLSearchParams();
      params.set("keyword", autocomplete.searchQuery);
      router.push(routes.search(/*page*/ 1, "image", params, /*withDefaultParams*/ true));
    }
  };

  /*
    this hook is responsible for binding the UI to options instead of directly read from data.
    by doing this, we can ensure that the UI won't go blank when querying by other keywords
  */
  useEffect(() => {
    if (autocompleteResults) {
      setOptions(autocompleteResults?.getArtworkAutocompleteResults);
    }
  }, [autocompleteResults]);

  // this hook is responsible for updating the autocomplete atom based on the query params
  useEffect(() => {
    setAutocomplete(prev => ({
      ...prev,
      searchQuery: searchParams?.get("keyword") ?? "",
    }));
  }, [searchParams, setAutocomplete]);

  return (
    <div className={props.className}>
      <Combobox value={autocomplete.searchQuery} onChange={handleOnSearchQueryChange}>
        {({ open }) => {
          setIsOpen(open);
          //
          return (
            <div className="relative">
              <div className={clsMerge("relative inline-flex w-full", zSearchBar.className)}>
                <ArtworkMediaTypeDropdown
                  className={clsMerge(
                    "absolute inset-y-0 hidden w-[106px] pr-6 focus:outline-none md:block",
                    zArtworkMediaTypeDropdown.className,
                  )}
                />

                <Combobox.Button
                  className="absolute inset-y-0 left-0 px-[17px] md:hidden"
                  onClick={() => handleOnSearchQueryChange("")}
                >
                  <FontAwesomeIcon
                    className="text-[#B4B4B4]"
                    height={24}
                    icon={faSearch}
                    width={24}
                  />
                </Combobox.Button>

                <Combobox.Input
                  aria-expanded={false}
                  className={clsMerge(
                    "font-poppins",
                    "h-12 w-full text-base leading-6 text-gray-900 md:h-16",
                    "border-0 bg-white py-4 pl-[64px] pr-8 md:pl-[180px]",
                    "ring-1  ring-[#343434]",
                    "placeholder-[#87888C] focus-within:ring-[#343434] focus:outline-none",
                  )}
                  displayValue={() => autocomplete.searchQuery}
                  placeholder={t("SEARCH_BAR.PLACEHOLDER")}
                  onBlur={() => {
                    document.documentElement.style.zoom = "1.0";
                  }}
                  onChange={event => handleOnSearchQueryChange(event.target.value)}
                  onKeyUp={handlePressEnter}
                />

                {autocomplete.searchQuery && (
                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center pr-6"
                    onClick={() => handleOnSearchQueryChange("")}
                  >
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-[#343434]" />
                  </Combobox.Button>
                )}
              </div>

              {autocomplete.searchQuery && open && (
                <Combobox.Options
                  className={clsMerge(
                    "absolute max-h-80 w-full overflow-auto bg-white",
                    "py-1 ring-1 ring-black focus:outline-none sm:text-sm",
                    zSearchBarOptionItem.className,
                  )}
                >
                  <Combobox.Option
                    key={"option.user.input"}
                    value={autocomplete.searchQuery}
                    onKeyUp={handleClickAutocomplete(autocomplete.searchQuery)}
                  >
                    {({ active }) => (
                      <div
                        className={`w-full py-5 pl-8 pr-8 text-left ${
                          active ? "bg-[#DEEBFF] text-black" : "bg-white text-black"
                        }`}
                        onClick={handleClickAutocomplete(autocomplete.searchQuery)}
                      >
                        {autocomplete.searchQuery}
                      </div>
                    )}
                  </Combobox.Option>
                  {options?.map((artwork: any) => (
                    <Combobox.Option
                      key={artwork.extId}
                      value={artwork.metadata.name}
                      onKeyUp={handleClickAutocomplete(artwork.metadata.name)}
                    >
                      {({ active }) => (
                        <div
                          className={`w-full py-5 pl-8 pr-8 text-left ${
                            active ? "bg-[#DEEBFF] text-black" : "bg-white text-black"
                          }`}
                          onClick={handleClickAutocomplete(artwork.metadata.name)}
                        >
                          {artwork.metadata.name}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          );
        }}
      </Combobox>
    </div>
  );
};

export default ArtworkSearchAutocomplete;
