import { atom } from "recoil";

export interface State {
  searchQuery: string;
}

const initialState: State = {
  searchQuery: "",
};

const autocompleteAtom = atom({
  key: "AutoCompleteAtom",
  default: initialState,
});

export default autocompleteAtom;
